<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.isError = false)"
    ></AError>
    <AError
      v-if="otpApi.isError"
      :api="otpApi"
      :callbackReset="() => (otpApi.isError = false)"
    ></AError>
    <AError
      v-if="otpCheckApi.isError"
      :api="otpCheckApi"
      :callbackReset="() => (otpCheckApi.isError = false)"
    ></AError>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-card-title>Verify Account</v-card-title>
      <div class="d-flex align-center justify-space-around">
        <div v-if="!otpApi.isLoading" class="form-content text-center">
          <v-form ref="formOtp" v-model="valid" lazy-validation>
            <div>
              We sent One Time Password (OTP) to
              {{ this.mobile }}.
            </div>
            <div class="text-center pt-3">
              <v-text-field
                :rules="[
                  (v) => !!v || 'OTP is required',
                  () =>
                    Number.isInteger(Number(otp)) ||
                    'The OTP must be an integer number',
                ]"
                v-model="otp"
                length="6"
                block
                label="OTP"
                outlined
                clearable
              ></v-text-field>
            </div>
            <div>
              Didn't receive?
              <span v-if="resendTime > 0"> Wait {{ resendTime }} seconds.</span
              ><span v-else
                ><v-btn
                  color="primary"
                  text
                  @click="reSendOtp()"
                  :loading="otpApi.isLoading"
                >
                  Resend OTP
                </v-btn></span
              >
            </div>
            <div class="text-center pt-1">
              <v-btn
                width="75%"
                rounded
                color="primary"
                dark
                :loading="otpCheckApi.isLoading"
                @click="validateOtp()"
              >
                Verify
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn
                width="75%"
                rounded
                outlined
                color="primary"
                dark
                @click="decrease()"
              >
                Back
              </v-btn>
            </div>
          </v-form>
        </div>
        <div v-else class="form-content text-center">
          <div class="text-center pt-2">
            <v-progress-circular
              :size="80"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {},
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    valid: true,
    name: null,
    mobile: null,
    otp: null,
    nameRules: [],
    mobileRules: [],
    step: 1,
    resendTime: 60,
    setInterval: null,
    selectedCountry: {},
    countryCode: null,
    countries: [],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    otpApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    otpCheckApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  watch: {
    mobile: function () {
      this.mobileRules = [];
    },
    resendTime: function () {
      if (this.resendTime < 1) {
        clearInterval(this.setInterval);
      }
    },
  },
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: "Home",
      to: { name: "PageMainHome" },
      exact: true,
    });

    this.breadcrumbs.push({
      text: "Verify Account",
      to: { name: "PageEducatorAccountVerify" },
      exact: true,
    });

    this.api.method = "GET";
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/account/verify";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = () => {
      this.api.isLoading = false;
      var authData = this.$_.cloneDeep(this.auth);
      authData.Account.isVerifiedMobile = true;
      this.$store.commit("updateAuth", authData);
      this.$router.push({ name: "PageEducatorDashboard" });
    };

    this.otpApi.url =
      this.$api.servers.log + "/api/v1/" + this.$_getLocale() + "/otp/send";

    this.otpApi.callbackReset = () => {
      this.otpApi.isLoading = true;
      this.otpApi.isError = false;
    };

    this.otpApi.callbackError = (e) => {
      this.otpApi.isLoading = false;
      this.otpApi.isError = true;
      this.otpApi.error = e;
    };

    this.otpApi.callbackSuccess = () => {
      this.otpApi.isLoading = false;
      this.step = 2;
      this.resendTime = 60;
      this.startTime();
    };

    this.otpCheckApi.url =
      this.$api.servers.log + "/api/v1/" + this.$_getLocale() + "/otp/check";

    this.otpCheckApi.callbackReset = () => {
      this.otpCheckApi.isLoading = true;
      this.otpCheckApi.isError = false;
    };

    this.otpCheckApi.callbackError = (e) => {
      this.otpCheckApi.isLoading = false;
      this.otpCheckApi.isError = true;
      this.otpCheckApi.error = e;
    };

    this.otpCheckApi.callbackSuccess = () => {
      this.otpCheckApi.isLoading = false;
      this.$api.fetch(this.api);
    };
  },
  mounted() {
    this.mobile = this.auth.Account.mobile;
    this.sendOtp();
  },

  methods: {
    close() {
      this.$router.push({ name: "PageEducatorDashboard" });
    },
    increase() {
      this.step++;
    },
    decrease() {
      this.step--;
      this.otp = null;
    },
    sendOtp() {
      this.otpApi.params = {
        mobile: this.mobile,
        countryCode: this.countryCode,
        purpose: "account_verify",
      };
      this.$api.fetch(this.otpApi);
    },
    reSendOtp() {
      this.otpApi.callbackReset();

      this.otpApi.params = {
        mobile: this.mobile,
        countryCode: this.countryCode,
        purpose: "account_verify",
      };
      this.$api.fetch(this.otpApi);
    },
    validateOtp() {
      if (this.$refs.formOtp.validate()) {
        this.otpCheckApi.params = {
          otp: this.otp,
          mobile: this.mobile,
          purpose: "account_verify",
        };
        this.$api.fetch(this.otpCheckApi);
      } else {
        this.otpApi.callbackError("Please check the form.");
      }
    },
    startTime() {
      this.setInterval = setInterval(() => {
        if (this.resendTime > 0) {
          this.resendTime = this.resendTime - 1;
        }
      }, 1000);
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 300px !important;
}
</style>